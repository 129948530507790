import { ReactComponent as DashboardIcon } from "@images/icons/dashboard.svg"
import { ReactComponent as GitbookIcon } from "@images/icons/gitbook.svg"
import { ReactComponent as GithubIcon } from "@images/icons/github.svg"
import { ReactComponent as DiscordIcon } from "@images/icons/discord.svg"
import { ReactComponent as TwitterIcon } from "@images/icons/twitter.svg"
import { ReactComponent as MediumIcon } from "@images/icons/medium.svg"

import arbIcon from "@images/icons/arbitrum.svg"
import tronIcon from "@images/icons/tron.svg"
import ERC20 from "@assets/abis/ERC20.json";
import TRC20 from "@assets/abis/TRC20.json";

const social = [
  {
    name: 'Dashboard',
    link: 'https://app.sweepr.finance/',
    icon: DashboardIcon
  },
  {
    name: 'GitBook',
    link: 'https://docs.sweepr.finance/',
    icon: GitbookIcon
  },
  {
    name: 'Github',
    link: 'https://github.com/SweeprFi',
    icon: GithubIcon
  },
  {
    name: 'X',
    link: 'https://twitter.com/SweeprFi',
    icon: TwitterIcon
  },
  {
    name: 'Discord',
    link: 'https://discord.gg/dnJ7MMgQWH',
    icon: DiscordIcon
  },
  {
    name: 'Medium',
    link: 'https://medium.com/Sweepr',
    icon: MediumIcon
  }
]

const chainList = [
  {
    chainId: 42161,
    name: 'Arbitrum',
    logo: arbIcon,
    abi: ERC20,
    rpc: `https://rpc.ankr.com/arbitrum`,
    placeholder: '0x4184···153c4d',
    token: '0xB88a5Ac00917a02d82c7cd6CEBd73E2852d43574',
    gateway: '0x0Fa205c0446cD9EeDCc7538c9E24BC55AD08207f',
    scanLink: 'https://arbiscan.io/tx/',
    bytes: '0x3432313631000000000000000000000000000000000000000000000000000000'
  },
  {
    chainId: 728126428,
    name: 'Tron',
    logo: tronIcon,
    abi: TRC20,
    rpc: `https://rpc.ankr.com/http/tron`,
    placeholder: 'TBia4u···v15Vhi',
    token: 'TMGthL6Co2B9wgSyiSC5vdReEnbDafF72p',
    gateway: 'TA9u5hEB6iRKLujcWZ3Le4EhuQH4YqytD6',
    scanLink: 'https://tronscan.org/#/transaction/',
    bytes: '0x3732383132363432380000000000000000000000000000000000000000000000'
  }
]

// const chainList = [
//   {
//     chainId: 43113,
//     name: 'Arbitrum',
//     logo: arbIcon,
//     abi: ERC20,
//     rpc: `https://api.avax-test.network/ext/bc/C/rpc`,
//     placeholder: '0x4184···153c4d',
//     token: '0x69dc97bb33e9030533ca2006ab4cef67f4db4125',
//     tokenHex: '0x69dc97bb33e9030533ca2006ab4cef67f4db4125',
//     gateway: '0x2dc0cc49904efe8744c103269269610739b72df3',
//     scanLink: 'https://testnet.snowtrace.io/tx/',
//   },
//   {
//     chainId: 2494104990,
//     name: 'Tron',
//     logo: tronIcon,
//     abi: TRC20,
//     rpc: `https://api.shasta.trongrid.io`,
//     placeholder: 'TBia4u···v15Vhi',
//     token: 'TNRzsspDCRBf7EEyTuy3ZkXEz9oXMHA8oL',
//     hex: '0x88b29eb27ada9923d0eaa0bcbbac5d4ffa445082',
//     gateway: 'TBxBtMS1GmeKYy9fKUH6vubjRebvMAMndH',
//     scanLink: 'https://shasta.tronscan.org/#/transaction/',
//   }
// ]

export { social, chainList }