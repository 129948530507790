import React, {
    useContext,
    useEffect,
    useMemo,
    useCallback,
    useState,
} from 'react';
import Web3 from 'web3';
import { ethers } from 'ethers';

import injectedModule from '@web3-onboard/injected-wallets'
import walletConnectModule from '@web3-onboard/walletconnect'
import coinbaseWalletModule from '@web3-onboard/coinbase'
import { useConnectWallet, init, useSetChain } from '@web3-onboard/react'

import { chainList } from '@config/constants'
import walletLogo from "@images/logo.svg";

// Default = Arbitrum
const CHAIN_ID = chainList[0].chainId
const CHAIN_NAME = chainList[0].name
const RPC_URL = chainList[0].rpc

const injected = injectedModule()
const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: true })
const walletConnect = walletConnectModule({ projectId: 'cd4a577bcbeff487bba452a9deeaeace' })

const walletInfo = {
    wallets: [
        injected,
        coinbaseWalletSdk,
        walletConnect
    ],
    theme: 'dark',
    chains: [
        // {
        //     id: '0xa4b1',
        //     token: 'ETH',
        //     label: 'Arbitrum Mainnet',
        //     rpcUrl: `https://arbitrum-mainnet.infura.io`
        // },
        {
            id: '0xA869',
            token: 'AVAX',
            label: 'Avalanche Fuji',
            rpcUrl: `https://api.avax-test.network/ext/bc/C/rpc`
        }
    ],
    connect: {
        showSidebar: true,
        removeWhereIsMyWalletWarning: true,
        autoConnectAllPreviousWallet: true
    },
    notify: { enabled: false },
    appMetadata: {
        name: "Sweep Protocol",
        icon: walletLogo,
        logo: walletLogo,
        description: "Sweep Bridge wallet connect"
    },
    accountCenter: {
        desktop: { enabled: false, minimal: true },
        mobile: { enabled: false, minimal: true }
    },
}
init(walletInfo)

const UseWalletContext = React.createContext(null);
const useWallet = () => {
    const walletContext = useContext(UseWalletContext)

    if (walletContext === null) {
        throw new Error(
            'useWallet() can only be used inside of <UseWalletProvider />, ' +
            'please declare it at a higher level.'
        )
    }

    const { walletData } = walletContext

    return useMemo(() => ({ ...walletData }), [walletData])
}

const UseWalletProvider = (props) => {
    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()
    const [, setChain] = useSetChain()
    const walletContext = useContext(UseWalletContext)

    if (walletContext !== null) {
        throw new Error('<UseWalletProvider /> has already been declared.')
    }

    const [web3, setWeb3] = useState(undefined);
    const [connected, setConnected] = useState(false);
    const [walletAddress, setWalletAddress] = useState('');
    const [chainId, setChainId] = useState(props.chainId);
    const [signer, setSigner] = useState(undefined);

    useEffect(() => {
        const httpProvider = new Web3.providers.HttpProvider(RPC_URL, { timeout: 10000 })
        const _web3 = new Web3(wallet?.provider || httpProvider)
        setWeb3(_web3)
    }, [wallet, setWeb3])

    const extractAccount = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const _signer = provider.getSigner();
        setSigner(_signer);
        return _signer;
    };

    const walletInitialize = useCallback(async () => {
        const _address = wallet?.accounts[0]?.address
        const _chainId = parseInt(wallet?.chains[0].id)
        if (_address && _chainId !== chainId) {
            setChain({ chainId: parseInt(chainId) });
        } else if (_address) {
            setConnected(true)
            setWalletAddress(_address)
            setChainId(_chainId)
            await extractAccount();
        }
    }, [wallet, chainId, setChain, setConnected, setWalletAddress]);

    const connectHandler = useCallback(async () => {
        await connect();
        return await extractAccount();
    }, [connect])

    const disconnectHandler = useCallback(async () => {
        if (wallet) {
            await disconnect(wallet)
        }
        setConnected(false)
        setWalletAddress('')
        setSigner(undefined)
        window.localStorage.removeItem('connectedWallets')
    }, [wallet, disconnect, setConnected, setWalletAddress])

    const handleNetworkChange = useCallback(async (networkId) => {
        const _chainId = parseInt(networkId)

        if (_chainId !== chainId) {
            setChain({ chainId: parseInt(chainId) });
        } else {
            const _address = wallet?.accounts[0]?.address
            if (_address) {
                setConnected(true)
                setWalletAddress(_address)
            }
        }
    }, [wallet, chainId, setChain])

    useEffect(() => {
        walletInitialize()

        if (typeof window !== "undefined") {
            if (window.ethereum) {
                window.ethereum.on('chainChanged', handleNetworkChange);
                window.ethereum.on('accountsChanged', disconnectHandler);
            }
        }

    }, [handleNetworkChange, walletInitialize, disconnectHandler])

    const walletData = useMemo(
        () => ({ web3, chainId, connected, connecting, walletAddress, connectHandler, setChain, disconnectHandler, signer }),
        [web3, chainId, connected, connecting, walletAddress, connectHandler, setChain, disconnectHandler, signer]
    )

    return (
        <UseWalletContext.Provider value={{ walletData }}>
            {props.children}
        </UseWalletContext.Provider>
    )
}

UseWalletProvider.defaultProps = { chainId: CHAIN_ID, chainName: CHAIN_NAME }
export { UseWalletProvider, useWallet }
