import { useState, useEffect } from 'react';

const configuration = {
    isWidget: true,
    widgetId: "88",
    fromChain: "42161",
    toChain: "728126428",
    fromToken: "0xB88a5Ac00917a02d82c7cd6CEBd73E2852d43574",
    toToken: "0xCBF0A663E5D7657792A7094FA5FB6D07D3704A10",
    ctaColor: "#0d7bab",
    textColor: "#FFFFFF",
    backgroundColor: "#050505",
    logoURI: "https://sweepr.netlify.app/logo.png",
    slippageTolerance: "1",
    display: "vertical",
    isFromSelLocked: "1",
    isToSelLocked: "1",
};

const Home = () => {
    const [iframeUrl, setIframeUrl] = useState("");

    useEffect(() => {
        const baseUrl = "https://app.routernitro.com/swap";
        const paramString = new URLSearchParams(configuration).toString();
        setIframeUrl(`${baseUrl}?${paramString}`);
    }, []);

    return (
        <div className="bg flex justify-center pt-14">
            <iframe
                title="Sweep bridge"
                id="widget__iframe"
                height="475px"
                width="475px"
                src={iframeUrl}
                style={{ border: "0px solid red", outline: "none" }}
            ></iframe>
        </div>

    );
};

export default Home;
