import logo from '@images/logo.svg';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

function Header() {
  return (
    <Navbar expand="lg" className="bg-black">
      <Container>
        <Navbar.Brand href="/">
            <img src={logo} alt="logo" />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}

export default Header;